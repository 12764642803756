import * as React from "react"
import logo from '../images/logo.png';

import styled from 'styled-components';
import {GlobalStyle} from '../ui/global';

const IndexPage = () => {
  return (
    <>
      <GlobalStyle/>
      <MainStyled>
        <title>manapool</title>
        <LogoStyled />
        <Description>Разработка мобильных и веб-приложений</Description>
        <Contacts>
          <li><a href="mailto:go@manapool.net">go@manapool.net</a></li>
        </Contacts>
      </MainStyled>
    </>

  )
}

const MainStyled = styled.main`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
`;

const LogoStyled = styled.img.attrs({ src: logo })`
  max-width: 100%;
  margin: 80px;
`;

const Description = styled.p`
  font-size: 32px;
  text-align: center;
`;

const Contacts = styled.ul`
  margin-top: 80px;
  
  list-style: none;
  font-size: 28px;
  text-align: center;
`;

export default IndexPage
